<template>
  <div class="delivery-card" v-if="delivery">
    <div class="" >
      <div class="mb-1 text-muted">
        <span class="font-weight-bold d-flex justify-content-between">
          <span> Spot {{delivery.spot}}</span>
          <span>
            <b-dropdown variant="light" right size="sm">
              <template slot="button-content">
                  <i class="mdi mdi-dots-vertical"></i>
              </template>
              <b-dropdown-item-button @click="viewDelivery()"><i class="mdi mdi-eye"></i> View delivery </b-dropdown-item-button>
              <b-dropdown-item-button @click="updateDelivery('WINNER')"><i class="mdi mdi-trophy"></i> Make a winner</b-dropdown-item-button>
              <b-dropdown-item-button @click="updateDelivery('FINALIST')"><i class="mdi mdi-trophy-award"></i> Make finalist</b-dropdown-item-button>
              <b-dropdown-item-button @click="updateDelivery('PROGRESS')"><i class="mdi mdi-emoticon-confused-outline"></i> Make progress </b-dropdown-item-button>
              <b-dropdown-item-button @click="updateDelivery('BLOCKED')"><i class="mdi mdi-emoticon-happy"></i> Block  </b-dropdown-item-button>
              <b-dropdown-item-button @click="updateDelivery('ELIMINATED')"><i class="mdi mdi-emoticon-happy"></i> Eliminate  </b-dropdown-item-button>
            </b-dropdown>
          </span>
        </span>
      </div>
      <div @click.prevent="viewDelivery" class="delivery-first-file shadow"
        :style="`background-image: url(${absoluteUrl(getFirstFile)})`" >
      </div>
      <div class="">
        <div class="mt-1 text-center">
          <p class="font-sm mb-0" v-if="delivery.worker" style="color:#ccc;">
            {{delivery.files.length}} file(s) <router-link class="text-capitalize" :to="`/users/${delivery.worker.id}`" >
            by {{delivery.worker.username}} </router-link></p>
        </div>
        <div class="d-flex justify-content-center">
          <ul v-if="delivery.is_reviewed" class="list-inline mb-0">
            <li class="list-inline-item mr-1" v-for="star in parseInt(delivery.review.rating)" :key="'0-'+star">
              <img :src="absoluteUrl('/images/svg/star.svg')" alt="Review rating" width="12"></li>
            <li class="list-inline-item mr-1" v-for="nostar in  ((delivery.review.rating % 1 == 0 ? 5 : 4) - parseInt(delivery.review.rating))" :key="nostar">
              <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12"></li>
          </ul>
          <ul v-else class="list-inline mb-0">
            <li class="list-inline-item mr-1" v-for="nostar in  5" :key="nostar">
              <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
   props: {
    delivery : { type: Object,  default: null },
    dIndex: { type: Number, required : true},
  },
  computed:{
    getFirstFile(){
      return this.delivery.files[0] ? this.delivery.files[0].path : ''
    },
  },
  methods: {
    updateDelivery(status){
      const formData = new FormData()
      formData.append('data', JSON.stringify({status: status}))
      Swal.fire({
        title: "Are you sure?",
        text: `You are about to ${status} this desiger!`,
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#323a46",
        confirmButtonText: "Proceed!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post(`/deliveries/${this.delivery.id}/update`,formData)
            .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data.delivery)
              if(status == "FINALIST" || status == "WINNER"){
                this.$store.dispatch("orderList/fetchOrder", this.delivery.order_id)
              }
              this.resetForm();
            }
          })
        }
      });
    },
    viewDelivery(){
      this.$router.push({path: `/orders/${this.delivery.order_id}/deliveries/${this.delivery.id}`})
    }
  },

}
</script>

<style lang="scss">
.delivery-card .delivery-first-file {
    height:150px;
    background-position: center center;
    background-size: cover;
}

@media(max-width:568px){
    .delivery-card .delivery-first-file {
        height:100px;
    }
}

</style>
